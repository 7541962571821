.btn{
    border-radius:50px;
    background-color: grey;
    color: white;
    font-weight: 300;
    font-size: 1.8rem;
    padding: 1rem 4rem;
    border: 2px solid transparent;
    font-weight: 600;
    transition: all 225ms linear;
    &--primary{
        background-color: $primary;
        color: $lighter !important;
        &:hover{
            border: 2px solid $primary;
            background-color: transparent;
            color: $primary !important;
            text-decoration: none !important;
        }
    }
    &--secondary{
        background-color: white;
        color: $primary !important;
        &:hover{
            border: 2px solid white;
            background-color: transparent;
            color: white !important;
            text-decoration: none !important;
        }
    }
    &--small{
        padding: 0.8rem 2rem;
    }
}