.block{
    margin-top:15rem;
    a{
        display: inline-block;
        margin-top: 2rem;
    }
}
.list{
    list-style: disc;
    line-height: 140%;
    font-size: 2.6rem;
    list-style-position: inside;
    margin-top: 5rem;
}