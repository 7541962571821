
$layout-spacing: 0 4rem;
$breakpoints: (
  xs: 0,
  xxsm:330px,
  xsm:420px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl:1920px
) !default;

$breakpointsHeight: (
  xs: 0,
  xsm:375px,
  sm: 500px,
) !default;


$primary:#125AED;
$lighter:#fff;