@import "../../scss/base/variables";

.anchorlink{
    pointer-events: none;
}

.mobileselector{
    color:$lighter !important;
    font-size: 2rem !important;
    margin-top: 2rem !important;
}