@import "../scss/base/variables";
@import "../scss/base/mediaqueries";

.wrapper{
    margin-top:15rem;
}

.list{
   margin-top: 15rem; 
}

.list__item{
    margin-bottom: 5rem;
    h3{
        margin-bottom: 1rem;
    }
 }