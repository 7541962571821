.bm-item {
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: $lighter;
    transition: color 0.2s;
    outline: $lighter;
    font-weight: 600;
    font-size: 6.4rem;
    @include below(lg){
        font-size: 6.4rem;
    }
    @include below(md){
        font-size: 5.4rem;
    }
    @include below(sm){
        font-size: 9vw;
    }
  }
  
  .bm-item:hover {
    color: white;
  }
  
  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 21px;
    right: 4rem;
    top: 32px;
    outline: $lighter;
    @include above(lg){
        display: none;
    }
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #000;
    height: 3px !important;
    &:nth-child(2){
        top:9px !important;
    }
    &:nth-child(3){
        top:18px !important;
    }
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px !important;
    width: 30px !important;
    right: 4rem !important;
    top: 32px !important;
    z-index: 1100 !important;
    outline: $lighter;
    > span{
        height: 30px !important;
        width: 30px !important;
        right: 0 !important;
        top: 0 !important;
    }
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: $lighter;
    height: 30px !important;
    right:0;
    margin-right: 14px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: $primary;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .menu-item{
    transition: all 250ms ease;
    &:hover{
      text-decoration: none;
      opacity: 0.5;
    }
  }