@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700;900&display=swap');
html{
    font-size: 62.5%;
}
body{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
}
p{
    line-height: 139%;
    font-size: 2.6rem;
    font-weight: 300;
    @include below(xxsm){
        font-size: 2rem;
    }
}
li{
    font-weight: 300;
}
a{
    color:$primary;
    transition: all 250ms ease;
    &:hover{
        text-decoration: underline;
    }
} 

h1{
    font-size: 9.6rem;
    font-weight: 900;
    line-height: 104%;
    margin-bottom: 2rem;
    @include below(md){
        font-size: 7.6rem;
    }
    @include below(xsm){
        font-size: 15vw;
    }
    @include belowWidthHeightOrient(lg,sm, landscape) {
        font-size:4.6rem;
    }
    
}

h2{
    font-size: 7.4rem;
    font-weight: 900;
    line-height: 117%;
    @include below(md){
        font-size: 11.2vw;
    }
    @include below(sm){
        font-size: 10vw;
    }
}

h3{
    font-size: 3.4rem;
    font-weight: 900;
    line-height: 117%;
}