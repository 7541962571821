.grid-col{
    &--2,&--3{
        display: grid;
    }
    &--2{
        grid-template-columns: 1fr 1fr;
        @include below(lg){
            grid-template-columns: 1fr;
        } 
    }
    &--3{
        grid-template-columns: 1fr 1fr 1fr;
        @include below(xl){
            grid-template-columns: 1fr 1fr;
        } 
        @include below(lg){
            grid-template-columns: 1fr;
        } 
    }
}