@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";

.list{
    display: flex;
    @include below(lg){
        display: none;
    }
    li{
        padding-right: 1rem;
        a{
            color:black;
            transition: all 250ms ease;
            &:hover{
                color:$primary;
                text-decoration: none;
            }
            
        } 
    }
}