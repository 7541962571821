body,html,#root{
    height: 100%;
}
button:focus{
    background-color: transparent;
    border:none;
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
    outline: transparent;
}

.bm-overlay{
    transform: translate3d(0%, 0px, 0px) !important;
    z-index: 0 !important;
    position: inherit !important;
}

.main-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main{
    flex: 1;
}