@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.wrapper{
    justify-content: center;
    @include below(md){
        flex-direction: column;
    }
}

.trombimg{
    height: 320px;
    max-width: 100%;
}
.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding:0 2rem;
    margin-top: 7.5rem;
    flex-basis: 100%;
    @include below(md){
        flex-basis: 100%;
    }
    > *{
        max-width: 480px;
    }
}

.title{
    margin-top: 4.5rem;
    color:$primary;
    font-weight: 600;
    font-size: 2.4rem;
    flex-grow: 1;
}

.subtitle{
    margin-top: 1rem;
}

.desc{
    margin-top: 4.5rem;
    text-align: center;
    font-size: 1.8rem;
}

.info{
    flex-grow: 1;
    text-align: center;
}