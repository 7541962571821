.wrapper{
    margin-top: 15rem;
    position: relative;
    z-index: 100;
    &.test{
        margin-top: 50rem;
    }
}

.desc{
    margin-top: 5rem;
    max-width: 1200px;
    font-weight: 300;
}