.wrapper{
    margin-top:15rem;
}

.block{
    margin-top:15rem;
}

.list{
    max-width: 1200px;
    list-style: disc;
    list-style-position: inside;
    li{
        margin-top: 1rem;
        line-height: 139%;
        font-size: 2.6rem;
    }

}