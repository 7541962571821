@import "../../scss/base/variables";
.languageSelector{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $primary;
    font-weight: 600;
    text-transform: uppercase;
    transition: opacity 225ms linear;
    font-size: 1.4rem;
    li{
        padding-top: 2px;
    }
    &:hover{
        opacity: 0.5;
    }
    &.mobileselector{
        color:$lighter;
    }
}