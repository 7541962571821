@import "../scss/base/variables";
@import "../scss/base/mediaqueries";
.sectionBubble{
    display: flex;
    @include below(md){
        flex-direction: column;
    }

}
.probTitle{
    margin-top: 15rem;
    position: relative;
}