.cta{
    background-image: url(../../img/bg-cta.jpg);
        background-position: center;
        background-size:cover;
        background-repeat: no-repeat;
        width: 100%;
        margin-top: 15rem;
        color: white;
        padding: 8rem 0;
}
.blocktext{
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title, .desc{
    margin-bottom: 3rem;
}
.textchoice{
    margin-bottom: 2rem;
}
.form{
    margin-bottom: 3rem;
    label{
        margin-left: 5px;
        margin-right: 20px;
    }
}

.disclaimer{
    margin-bottom: 2rem;
    font-size: 1.5rem;
}