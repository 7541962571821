@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.banner{
    height: 100%;
    .blocktext {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: calc(100vh - 90px);
        position: relative;
        z-index: 100;
        > * {
            max-width: 830px;
        }
    }
    .illus{
        background-image: url(../../img/illus-banner-x3.png);
        background-position: bottom right;
        background-size:830px 920px;
        background-repeat: no-repeat;
        height: 920px;
        width: 100%;
        position: absolute;
        top: 230px;
        right: 0;
        @include below(xxl){
            background-image: url(../../img/illus-banner-x1.png);
        }
        @include below(md){
            background-position: bottom left;
            top: 100px;

        }

    }
    a{
        margin-top: 4rem;
    }
}
.title{
    font-size:9.6rem;
    font-weight: 900;
    line-height: 104%;
    @include below(md){
        font-size: 7.6rem;
    }
    @include below(xsm){
        font-size: 15vw;
    }
    @include belowWidthHeightOrient(lg,sm, landscape) {
        font-size:4.6rem;
    }
}
.desc{
    margin-top:2rem;
    font-weight: 300;
    @include belowWidthHeightOrient(lg,xsm, landscape) {
        font-size: 2rem;
    }
}