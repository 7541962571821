@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.mainheader{
    padding:2rem 0;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

