@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.wrapper{
    margin-top: 15rem;
    position: relative;
    z-index: 100;
    &.test{
        margin-top: 50rem;
    }
}

.subtitle{
    margin-top: 5rem;
}

.desc{
    max-width: 1200px;
    list-style: disc;
    font-weight: 300;
    list-style-position: inside;
    li{
        margin-top: 1rem;
        line-height: 139%;
        font-size: 2.6rem;
    }

}