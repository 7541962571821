@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.wrapper{
    margin-top: 15rem;
    position: relative;
    z-index: 100;
    width: 100%;
    overflow: hidden;
    &.test{
        margin-top: 50rem;
    }
    @include below(md){
        margin-top: 8rem;

    }
}
.container{
    display: flex;
    justify-content: center;
}
.bubble1{
    background-image: url(../../img/bubble1.svg);
    background-position: center center;
    background-size:contain;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    width: 100%;
}
.bubble2{
    background-image: url(../../img/bubble2.svg);
    background-position: center center;
    background-size:contain;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    width: 100%;
}
.bubbleWrapper{
    position: relative;
    height: 400px;
    max-width: 518px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.desc{
    position: absolute;
    text-align: center;
    font-size: 1.6rem;
    max-width: 250px;
}
