$orientations: (
  landscape: landscape,
  portrait: portrait
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include above(sm) {}
@mixin above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value + 1) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include below(sm) {}
@mixin below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//
//  RESPOND BELOW AND ORIENTATION
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include below(sm) {}
@mixin belowAndOrient($breakpoint,$orient) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    $orientation: map-get($orientations, $orient);
    // Write the media query.
    @media (max-width: ($breakpoint-value))and (orientation : ($orientation)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include between(sm, md) {}
@mixin between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    // Write the media query.
    @media (min-width: $lower-breakpoint + 1) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
//
//  RESPOND ABOVES
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include between(sm, md, landscape) {}
@mixin betweenOrientation($lower, $upper, $orient) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) and map-has-key($orientations, $orient){
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    $orientation: map-get($orientations, $orient);
    // Write the media query.
    @media (min-width: $lower-breakpoint + 1) and (max-width: ($upper-breakpoint)) and (orientation : ($orientation)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//
//  RESPOND BELOW HEIGHT
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include below(sm) {}
@mixin belowHeight($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpointsHeight, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpointsHeight, $breakpoint);
    // Write the media query.
    @media (max-height: ($breakpoint-value)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW AND ORIENTATION
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include below(sm) {}
@mixin belowWidthHeightOrient($breakpoint,$breakpointH, $orient) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    $breakpoint-valueH: map-get($breakpointsHeight, $breakpointH);
    $orientation: map-get($orientations, $orient);
    // Write the media query.
    @media (max-width: ($breakpoint-value)) and (max-height: ($breakpoint-valueH))and (orientation : ($orientation)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}