@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.wrapper{
    background-image: url(../../img/footer-bg.svg);
        background-position: bottom center;
        background-size:1105px 255px;
        background-repeat: no-repeat;
        height: 255px;
        margin-top: 15rem;
}
.info{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $lighter;
    
    @include below(md){
        align-items: flex-start;
    }
    @include below(xsm){
        padding-top: 3rem;
    }
    > *{
        text-align: center;
        margin-right: 30rem;
        font-weight: 300;
        font-size: 2rem;
        @include below(md){
            text-align: left;
            margin-right: 0rem;
        }
    }
    a{
        text-decoration: underline;
        transition: all 225ms linear;
        margin-top: 0.5rem;
        color: white;
        &:hover{
            opacity: 0.5;
        }
    }
}

.list{
    display: flex;
    margin-top: 3rem;
    li{
        margin: 0 1rem;
    }
}