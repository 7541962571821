@import "../../scss/base/variables";
@import "../../scss/base/mediaqueries";
.first-block{
    margin-top: 31.5rem;
    @include below(md){
        margin-top: 18.5rem;
    }
    @include below(sm){
        margin-top: 21rem;
    }
    @include belowWidthHeightOrient(lg,sm, landscape) {
        margin-top:10rem;
    }
}